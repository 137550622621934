import { createSlice, isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  TWorkOverviewType,
  TWorkOverviewView,
  TWorkOverviewState,
} from "./workOverview.types";
import type {
  FilterModel,
  StatsCardItem,
  GenericFilterItem,
  WorkOrder,
} from "../../../types";

/**
 * API
 */
import { getDailyWorkOrders, getSuspendedWorkOrders } from "./workOverview.api";
import { switchUser, loginUser } from "../../features";

/**
 * Imports utils
 */
import {
  defaultStatusFilters,
  preloadStatisticsState,
  preloadWorkOverviewViewState,
  preloadWorkOverviewTypeState,
  preloadLiveWOSearchValueState,
  addSocketEventDataToWorkOrders,
  preloadLiveWOFilterModelsState,
  preloadLiveWOStatusFiltersState,
} from "./workOverview.utils";
import { isToday } from "date-fns";
import { SocketEventPayload } from "../../services";

const initialState: TWorkOverviewState = {
  view: preloadWorkOverviewViewState(),
  statistics: preloadStatisticsState(),
  workOrderType: preloadWorkOverviewTypeState(),
  initialized: false,
  liveWorkOrders: {
    workOrders: [],
    loading: false,
    searchValue: preloadLiveWOSearchValueState(),
    statusFilters: preloadLiveWOStatusFiltersState(),
    filterModels: preloadLiveWOFilterModelsState(),
  },
  suspendedWorkOrders: {
    workOrders: [],
    hidden: true,
    loading: false,
    searchValue: "",
    filterModels: [],
  },
  draftWorkOrders: {
    workOrders: [],
    loading: false,
    searchValue: "",
    filterModels: [],
  },
};

const authChangeMatcher = isAnyOf(
  loginUser.matchFulfilled,
  switchUser.matchFulfilled,
);

export const workOverviewSlice = createSlice({
  name: ReduxSlice.WorkOverview,
  initialState,
  reducers: {
    updateWorkOverviewView: (
      state,
      action: PayloadAction<TWorkOverviewView>,
    ) => {
      state.view = action.payload;
    },
    updateWorkOverviewType: (
      state,
      action: PayloadAction<TWorkOverviewType>,
    ) => {
      state.workOrderType = action.payload;
    },
    updateStatistics: (state, action: PayloadAction<StatsCardItem[]>) => {
      state.statistics = action.payload;
    },
    updateSuspendedWorkOrdersFilterModels: (
      state,
      action: PayloadAction<FilterModel[]>,
    ) => {
      state.suspendedWorkOrders.filterModels = action.payload;
    },
    updateSuspendedWorkOrderSearchValue: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.suspendedWorkOrders.searchValue = action.payload;
    },
    updateLiveWorkOrdersFilterModels: (
      state,
      action: PayloadAction<FilterModel[]>,
    ) => {
      state.liveWorkOrders.filterModels = action.payload;
    },
    updateLiveWorkOrdersStatusFilters: (
      state,
      action: PayloadAction<GenericFilterItem[]>,
    ) => {
      state.liveWorkOrders.statusFilters = action.payload;
    },
    updateLiveWorkOrdersSearchValue: (state, action: PayloadAction<string>) => {
      state.liveWorkOrders.searchValue = action.payload;
    },
    resetLiveWorkOrdersFilters: (state) => {
      state.liveWorkOrders.filterModels = [];
      state.liveWorkOrders.statusFilters = defaultStatusFilters;
      state.liveWorkOrders.searchValue = "";
    },
    resetSuspendedWorkOrdersFilters: (state) => {
      state.suspendedWorkOrders.filterModels = [];
      state.suspendedWorkOrders.searchValue = "";
    },
    updateWorkOverviewLiveWorkOrders: (
      state,
      action: PayloadAction<WorkOrder[]>,
    ) => {
      state.liveWorkOrders.workOrders = action.payload;
    },
    updateWorkOverviewSuspendedWorkOrders: (
      state,
      action: PayloadAction<WorkOrder[]>,
    ) => {
      state.suspendedWorkOrders.workOrders = action.payload;
    },
    _updateWorkOverviewWorkOrdersLive: (
      state,
      action: PayloadAction<SocketEventPayload<WorkOrder>>,
    ) => {
      const event = action.payload.event;
      const workOrder = action.payload.model;

      if (event === "UPDATED") {
        const endedToday = isToday(new Date(workOrder.endDate));
        const hasPaymentType = workOrder.paymentType !== null;
        const isSuspended =
          state.liveWorkOrders.workOrders.findIndex(
            (_workOrder) => _workOrder.id === workOrder.id,
          ) === -1;

        if (hasPaymentType && isSuspended) {
          state.suspendedWorkOrders.workOrders =
            state.suspendedWorkOrders.workOrders.filter(
              (wo) => wo.id !== workOrder.id,
            );

          if (endedToday) {
            state.liveWorkOrders.workOrders = [
              workOrder,
              ...state.liveWorkOrders.workOrders,
            ];
          }
        }
      }

      if (state.liveWorkOrders.workOrders.length > 0) {
        state.suspendedWorkOrders.workOrders = addSocketEventDataToWorkOrders(
          state.suspendedWorkOrders.workOrders,
          action.payload,
        );
      }

      state.liveWorkOrders.workOrders = addSocketEventDataToWorkOrders(
        state.liveWorkOrders.workOrders,
        action.payload,
      );
    },
    deleteWorkOverviewWorkOrder: (state, action: PayloadAction<number>) => {
      if (state.liveWorkOrders.workOrders.length > 0) {
        state.liveWorkOrders.workOrders =
          state.liveWorkOrders.workOrders.filter(
            (workOrder) => workOrder.id !== action.payload,
          );
      }

      state.liveWorkOrders.workOrders = state.liveWorkOrders.workOrders.filter(
        (workOrder) => workOrder.id !== action.payload,
      );
    },
    toggleWorkOverviewSuspendedVisibility: (state) => {
      state.suspendedWorkOrders.hidden = !state.suspendedWorkOrders.hidden;
    },
    resetWorkOverviewState: (state) => {
      state.liveWorkOrders.workOrders = [];
      state.liveWorkOrders.filterModels = [];
      state.liveWorkOrders.statusFilters = defaultStatusFilters;
      state.liveWorkOrders.searchValue = "";

      state.suspendedWorkOrders.workOrders = [];
      state.suspendedWorkOrders.hidden = true;
      state.suspendedWorkOrders.filterModels = [];
      state.suspendedWorkOrders.searchValue = "";
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(getDailyWorkOrders.matchPending, (state) => {
      state.liveWorkOrders.loading = true;
    });
    builder.addMatcher(getSuspendedWorkOrders.matchPending, (state) => {
      state.suspendedWorkOrders.loading = true;
    });
    builder.addMatcher(
      getDailyWorkOrders.matchFulfilled,
      (state, { payload }) => {
        state.initialized = true;
        state.liveWorkOrders.loading = false;
        state.liveWorkOrders.workOrders = payload.data;
      },
    );
    builder.addMatcher(
      getSuspendedWorkOrders.matchFulfilled,
      (state, { payload }) => {
        state.suspendedWorkOrders.loading = false;
        state.suspendedWorkOrders.workOrders = payload.data;
        state.suspendedWorkOrders.hidden = false;
      },
    );
    builder.addMatcher(authChangeMatcher, (state) => {
      state.liveWorkOrders.filterModels = [];
      state.liveWorkOrders.statusFilters = defaultStatusFilters;
      state.liveWorkOrders.searchValue = "";

      state.suspendedWorkOrders.workOrders = [];
      state.suspendedWorkOrders.hidden = true;
      state.suspendedWorkOrders.filterModels = [];
      state.suspendedWorkOrders.searchValue = "";
    });
  },
});

export const workOverviewActionCreators = workOverviewSlice.actions;
export const workOverviewReducer = workOverviewSlice.reducer;
