/**
 * Defines the Redux slices used throughout the app
 */
export enum ReduxSlice {
  Snackbar = "snackbar",
  Message = "message",
  Auth = "auth",
  Account = "account",
  Clients = "clients",
  WorkOrder = "workOrder",
  TopbarContent = "topbarContent",
  Reports = "reports",
  Notifications = "notifications",
  WorkOverview = "workOverview",
  Appointments = "appointments",
  Device = "device",
  Socket = "socket",
  TyreHotels = "tyre-hotels",
}
