import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { ReduxSlice } from "../../features";
import type {
  SocketConnectionOptions,
  SocketState,
  TSocketStatus,
} from "./socket.types";

const initialState: SocketState = {
  status: "connecting",
};

export const socketSlice = createSlice({
  name: ReduxSlice.Socket,
  initialState,
  reducers: {
    /**
     * Action only carries payload to be intercepted by the scoket middleware
     */
    connectSocket: (
      _state,
      _action: PayloadAction<SocketConnectionOptions>,
    ) => {},
    disconnectSocket: () => {},
    setSocketState: (state, action: PayloadAction<TSocketStatus>) => {
      state.status = action.payload;
    },
  },
});

export const socketActionCreators = socketSlice.actions;
export const socketReducer = socketSlice.reducer;
