import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { toHashMap } from "../../utils";
import { searchClients } from "../../features";

import { ReduxSlice } from "../../features";
import type { Client } from "../../../types";
import type { ClientsState } from "./clients.types";
import type { SocketEventPayload } from "../../services";

/**
 * Defines the clients initial state
 */
const initialState: ClientsState = {
  loading: false,
  clients: [],
  clientsHashMap: {},
};

/**
 * Handles creating the a redux state slice
 */
export const clientsSlice = createSlice({
  name: ReduxSlice.Clients,
  initialState,
  reducers: {
    updateClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    updateClientsHashMap: (state, action: PayloadAction<Client[]>) => {
      state.clientsHashMap = toHashMap(action.payload, "id");
    },
    resetClientsState: (state) => {
      state.clients = [];
      state.clientsHashMap = {};
    },
    _updateClientsLive: (
      state,
      action: PayloadAction<SocketEventPayload<Client>>,
    ) => {
      const { event, model } = action.payload;

      if (event === "CREATED") {
        state.clients = [...state.clients, model];
      }

      if (event === "UPDATED") {
        state.clients = state.clients.map((item) =>
          item.id === model.id ? { ...model } : item,
        );
      }

      if (event === "DELETED") {
        state.clients = state.clients.filter((item) => item.id !== model.id);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(searchClients.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(searchClients.matchFulfilled, (state, { payload }) => {
      state.loading = false;
      state.clients = payload.data.items;
      state.clientsHashMap = toHashMap(payload.data.items, "id");
    });
  },
});

/**
 * Exports the action-creators
 */
export const clientsActionCreators = clientsSlice.actions;

/**
 * Exports the reducer
 */
export const clientsReducer = clientsSlice.reducer;
