import type { Dispatch } from "@reduxjs/toolkit";
import type { SocketEventPayload } from "../../../services";

import { accountActionCreators } from "../../../features";

export const generalAccountEventHandler = (
  payload: SocketEventPayload<any>,
  dispatch: Dispatch,
) => {
  switch (payload.modelName) {
    case "CarType":
      dispatch(accountActionCreators._updateAccountCarTypesLive(payload));
      break;
    case "PaymentType":
      dispatch(accountActionCreators._updateAccountPaymentTypesLive(payload));
      break;
    default:
      break;
  }
};
