import type { Dispatch } from "@reduxjs/toolkit";
import type { SocketEventPayload } from "../../../services";

import { isToday } from "date-fns";
import {
  accountActionCreators,
  clientsActionCreators,
  tyreHotelsActionCreators,
  appointmentsActionCreators,
} from "../../../features";

export const generalEventHandler = (
  payload: SocketEventPayload<any>,
  dispatch: Dispatch,
) => {
  switch (payload.modelName) {
    case "AppointmentGroup":
      dispatch(
        accountActionCreators._updateAccountAppointmentGroupsLive(payload),
      );
      break;
    case "User":
      dispatch(accountActionCreators._updateAccountUsersLive(payload));
      break;
    case "Worker":
      dispatch(accountActionCreators._updateAccountWorkersLive(payload));
      break;
    case "Client":
      dispatch(clientsActionCreators._updateClientsLive(payload));
      break;
    case "TyreHotel":
      dispatch(tyreHotelsActionCreators._updateTyreHotelsLive(payload));
      break;
    case "Appointments":
      const { model } = payload;
      const appointmentDate = new Date(model.from);

      if (isToday(appointmentDate)) {
        dispatch(
          appointmentsActionCreators._updateTodayAppointmentsLive(payload),
        );
      }

      dispatch(appointmentsActionCreators._updateAppointmentsLive(payload));

      break;
    default:
      break;
  }
};
