import { Middleware, isAnyOf } from "@reduxjs/toolkit";

import {
  generalEventHandler,
  workOrderEventHandler,
  generalAccountEventHandler,
} from "./handlers";
import { SocketClient } from "../../services";
import { socketActionCreators, fetchAccountInformation } from "../../features";

import type {
  SocketConnectionOptions,
  InitializeSocketConnectionFunc,
} from "./socket.types";
import type { RootReducer } from "../../../redux";
import type { AppStartListening } from "../../store";

export const socketMiddleware: Middleware<{}, RootReducer> = (store) => {
  const { dispatch } = store;

  let socketClient: SocketClient | null = null;

  const initializeSocketConnection: InitializeSocketConnectionFunc = (
    options,
  ) => {
    const { accessToken, userId, accountId, organizationId } = options;

    socketClient = new SocketClient()
      .setUserId(userId)
      .setAccountId(accountId)
      .setOrganizationId(organizationId);

    if (accessToken) {
      socketClient
        .connect(accessToken)
        .then(() => dispatch(socketActionCreators.setSocketState("connected")))
        .catch(() => dispatch(socketActionCreators.setSocketState("error")));
    }

    socketClient.on({
      withAuth: true,
      channel: "general",
      event: "GeneralEvent",
      callback: (payload) => {
        generalEventHandler(payload, dispatch);
      },
    });

    socketClient.on({
      withAuth: true,
      channel: "general-account",
      event: "GeneralAccountEvent",
      callback: (payload) => {
        generalAccountEventHandler(payload, dispatch);
      },
    });

    socketClient.on({
      withAuth: true,
      channel: "work-order",
      event: "WorkOrder",
      callback: (payload) => {
        workOrderEventHandler(payload, dispatch);
      },
    });
  };

  return (next) => (action) => {
    if (isAnyOf(socketActionCreators.connectSocket)(action)) {
      initializeSocketConnection(action.payload);
    }

    if (isAnyOf(socketActionCreators.disconnectSocket)(action)) {
      if (socketClient) {
        socketClient.disconnect();
        socketClient = null;

        dispatch(socketActionCreators.setSocketState("disconnected"));
      }
    }

    return next(action);
  };
};

export const addSocketListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(fetchAccountInformation.matchFulfilled),
    effect: (_action, listenerApi) => {
      const state = listenerApi.getState();
      const { auth, account } = state;

      const socketConnectionOptions: SocketConnectionOptions = {
        accessToken: auth.accessToken,
        userId: auth.user?.id,
        accountId: account.accountInformation.id,
        organizationId: auth.user?.organizationId,
      };

      /**
       * Disconnect from socket when an account switch occurs
       */
      if (
        state.socket.status === "connected" ||
        state.socket.status === "error"
      ) {
        listenerApi.dispatch(socketActionCreators.disconnectSocket());
      }

      listenerApi.dispatch(
        socketActionCreators.connectSocket(socketConnectionOptions),
      );
    },
  });
};
