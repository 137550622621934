import type { Dispatch } from "@reduxjs/toolkit";
import type { SocketEventPayload } from "../../../services";
import type { WorkOrder } from "../../../../types";

import { workOverviewActionCreators } from "../../../features";

export const workOrderEventHandler = (
  payload: SocketEventPayload<WorkOrder>,
  dispatch: Dispatch,
) => {
  switch (payload.modelName) {
    case "WorkOrder":
      dispatch(
        workOverviewActionCreators._updateWorkOverviewWorkOrdersLive(payload),
      );
      break;
    default:
      break;
  }
};
